<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from 'axios';
import { required, email } from "vuelidate/lib/validators";
import appConfig from "@/app.config";

export default {
  components: {
    Layout,
    PageHeader
  },
  page: {
    title: "Lotes",
    meta: [{ name: "description", content: appConfig.description }]
  },
  data() {
    return {
      title: "Detalhe lote",
      items: [
        {
          text: "Lotes",
          href: "/lot"
        },
        {
          text: "Detalhe",
          active: true
        }
      ],
      lot: null,
      img_url: null,
      default_img: require('@/assets/images/product/img-1.jpeg'),
      expandImg: null,
      user: {name: '', email: ''},
      submitted: false,
    };
  },
  validations: {
    user: {
      name: { required },
      email: { required, email }
    }
  },
  created(){
    const user = JSON.parse(localStorage.getItem('user'));
    let config = {
      method: 'get',
      url: `${process.env.VUE_APP_API}api/v1/admin/lot/${this.$route.params.id}`,
      headers: { 
        'Accept': 'application/json', 
        'Authorization': `Bearer ${user.access_token}`
      }
    };

    axios(config)
    .then( (response) => {
      this.lot = response.data.data.lot
      console.log(this.lot)
      // this.user = {
      //   name: this.admin.user.name,
      //   email: this.admin.user.email
      // }
    })
    .catch((error) => {
      console.log(error)
      // this.$router.push('/pages/error-404')
    });

    this.img_url = process.env.VUE_APP_IMG
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-overlay :show="submitted" rounded="sm">
      <div class="row" v-if="lot">
        <div class="col-sm-4">
          <div class="card h-100">
            <div class="card-header bg-transparent border-bottom">
              <h5 class="mb-0">Detalhes</h5>
            </div>

            <div class="card-body border-top">
              <div class="media">
                <div class="media-body align-self-center overflow-hidden">
                  <p class="text-muted mb-0">Título</p>
                  <h6 style="min-height: 34px">{{lot.title ? lot.title : lot.animals[0].name}}</h6>
                  <hr>
                </div>
                <div class="media-body align-self-center overflow-hidden">
                  <p class="text-muted mb-0">Status</p>
                  <h6 style="min-height: 34px">{{lot.status_text}}</h6>
                  <hr>
                </div>
              </div>

              <div class="media">
                <div class="media-body align-self-center overflow-hidden">
                  <p class="text-muted mb-0">Anunciante</p>
                  <h6>{{lot.user.name}}</h6>
                  <hr>
                </div>
              </div>

              <div class="media">
                <div class="media-body align-self-center overflow-hidden">
                  <p class="text-muted mb-0">Proprietário</p>
                  <h6>{{lot.user.name}}</h6>
                  <hr>
                </div>
              </div>

              <div class="media">
                <div class="media-body align-self-center overflow-hidden">
                  <p class="text-muted mb-0">Valor</p>
                  <h6>R$ {{lot.amount | price}}</h6>
                  <hr>
                </div>
                <div class="media-body align-self-center overflow-hidden">
                  <p class="text-muted mb-0">Parcelas</p>
                  <h6>{{ lot.installment ? lot.installment : "-" }}</h6>
                  <hr>
                </div>
                <div class="media-body align-self-center overflow-hidden">
                  <p class="text-muted mb-0">Tipo</p>
                  <h6>{{ lot.single_sale == 1 ? 'Venda direta' : 'Venda por lance'}}</h6>
                  <hr>
                </div>
              </div>

              <div class="media">
                <div class="media-body align-self-center overflow-hidden">
                  <p class="text-muted mb-0">Lance inicial</p>
                  <h6>R$ {{lot.start_bid | price}}</h6>
                  <hr>
                </div>
                <div class="media-body align-self-center overflow-hidden">
                  <p class="text-muted mb-0">Lance mínimo</p>
                  <h6>R$ {{lot.min_bid | price }}</h6>
                  <hr>
                </div>
              </div>

              <div class="media">
                <div class="media-body align-self-center overflow-hidden">
                  <p class="text-muted mb-0">Descrição</p>
                  <h6>{{lot.description}}</h6>
                  <hr>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-8">
          <div class="card h-100">
            <div class="card-header bg-transparent border-bottom">
              <h5 class="mb-0">Animais</h5>
            </div>
            <div class="card-body border-top">
              <div class="row no-gutters justify-content-md-center">
                <div class="col-sm-8" v-for="(item, index) in lot.animals" :key="index">
                  <div class="product-box">
                    <div class="product-img p-4">
                      <img
                        :src="item.medias.length ? item.medias[0].url : default_img"
                        alt
                        class="img-fluid mx-auto d-block mb-3"
                      />
                    </div>
                    <div class="text-center">
                      <p class="font-size-12 mb-1">Registro: {{item.registry}}</p>
                      <h5 class="font-size-15">
                        <a href="#" class="text-dark">{{item.name}}</a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div class="col-sm-12 mt-4">
          <div class="card h-100">
            <div class="card-header bg-transparent border-bottom">
              <h5 class="mb-0">Lances</h5>
            </div>

            <div class="card-body border-top">
              
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Proposta</th>
                    <th>Realizado em</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(bid, index) in lot.bids" :key="index">
                    <td>{{bid.user.name}}</td>
                    <td>R$ {{bid.amount | price}}</td>
                    <td>{{bid.created_at | date}}</td>
                  </tr>
                </tbody>
              </table>
              
            </div>
          </div>
        </div>
        
        <div class="col-sm-12 mt-4">
          <div class="card h-100">
            <div class="card-header bg-transparent border-bottom">
              <h5 class="mb-0">Perguntas e respostas</h5>
            </div>

            <div class="card-body border-top">
              
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th>Pergunta</th>
                    <th>Resposta</th>
                    <th>Criado em</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(qa, index) in lot.questions" :key="index">
                    <td>{{qa.question}}</td>
                    <td>{{qa.answer}}</td>
                    <td>{{qa.created_at | date}}</td>
                  </tr>
                </tbody>
              </table>
              
            </div>
          </div>
        </div>

      </div>
      <!-- end row -->

      <b-row v-else>
        <div class="col-xl-3 col-lg-4">
          <b-skeleton-img height="400px"></b-skeleton-img>
        </div>
        <div class="col-lg-9">
          <b-skeleton-img height="400px"></b-skeleton-img>
        </div>
      </b-row>
    </b-overlay>
  </Layout>
</template>